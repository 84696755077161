import React, {useEffect, useRef} from 'react';
import dynamic from 'next/dynamic';
import {withRouter} from 'next/router';

import styles from '@styles/common/Header.module.scss';
import {useApp} from '@context/AppProvider';

const TopHeader = dynamic(() => import('./subHeader/TopHeader.component'));
const MiddleHeaderComponent = dynamic(() =>
	import('./subHeader/MiddleHeader.component'),
);
const NavBarComponent = dynamic(() =>
	import('./subHeader/navBar/index.component'),
);
// modals
const LoginPop = dynamic(() => import('@models/login.modal'));
const RegisterPop = dynamic(() => import('@models/register/register.modal'));
const ForgotPasswordPop = dynamic(() => import('@models/forgotPassword.modal'));
const ResendActicationPop = dynamic(() =>
	import('@models/resendVerification.modal'),
);
const CloseImg = dynamic(() => import('@imageWrapper/closeImg'));

const wrapperRef = React.createRef();
class Header extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			showMenuMobile: false,
			loginModalShow: false,
			registerMobModalShow: false,
			registerModalShow: false,
			forgotModalShow: false,
			resendActivationModalShow: false,
			advanceSearchOpen: false,
		};
	}

	showHideMobileMenu = value => {
		this.setState({
			showMenuMobile: value,
			loginModalShow: false,
		});
	};

	showModel = key => {
		this.setState({
			forgotModalShow: key === 'forgot' ? !this.state.forgotModalShow : false,
			loginModalShow: key === 'login' ? !this.state.loginModalShow : false,
			registerModalShow:
				key === 'register' ? !this.state.registerModalShow : false,
			registerMobModalShow:
				key === 'mobRegister' ? !this.state.registerMobModalShow : false,
			resendActivationModalShow:
				key === 'activation' ? !this.state.resendActivationModalShow : false,
			showMenuMobile: false,
		});
	};

	render() {
		return (
			<>
				<header
					id='top-header'
					className={`${styles.header} ${
						this.state.advanceSearchOpen ? styles.searchOpenFilter : ''
					} ${this.state.showMenuMobile ? styles.menuImageOpen : ''}`}
				>
					{/* Top head bar start*/}
					<TopHeader styles={styles}> </TopHeader>
					{/* Top head bar end*/}

					{/* Middle header section  start*/}
					{!this.props.hideMiddleHeader && (
						<MiddleHeaderComponent
							styles={styles}
							hideMenu={this.props.hideMenu}
							hideGoodCause={this.props.hideGoodCause}
							hideSearch={this.props.hideSearch}
							showHideMobileMenu={this.showHideMobileMenu}
							showModel={this.showModel}
							advanceSearchOpen={this.state.advanceSearchOpen}
							setAdvanceSearchOpen={val => {
								this.setState({
									advanceSearchOpen: val,
								});
							}}
						>
							{' '}
						</MiddleHeaderComponent>
					)}

					{/* Middle header section end*/}

					{/* Nav bar start */}
					<NavBarComponent
						styles={styles}
						hideMenu={this.props.hideMenu}
						showModel={this.showModel}
						showHideMobileMenu={this.showHideMobileMenu}
					></NavBarComponent>
					{/* Nav bar end */}
					<div
						className={styles.boxOverley}
						onClick={() => this.showHideMobileMenu(false)}
					></div>
				</header>
				<Modals modalHide={this.showModel} state={this.state} />
			</>
		);
	}
}

const ShowMobRegister = ({modalHide, styles}) => {
	const hideShow = () => {
		document.body.style.overflow = 'overlay';
		modalHide('mobRegister');
	};

	const update = () => {
		setTimeout(() => {
			document.body.style.overflow = 'overlay';
			modalHide('mobRegister');
			modalHide('register');
		}, 10);
	};
	return (
		<React.Fragment>
			<div className={styles.mobSortOverlay}></div>
			<div className={styles.mobSortContents} ref={wrapperRef}>
				<div className={styles.closeButtonSort} onClick={hideShow}>
					<CloseImg />
				</div>{' '}
				<div className={`row ${styles.outer}`}>
					<h6 className={styles.mButtom}>
						Sign up to receive $10 off your first order. Plus, we give a
						donation to a Good Cause of your choice every time you shop with us
						- out of our pocket, not yours!
					</h6>

					<div className={`col-md-6 ${styles.margin}`}>
						<div className={`${styles.formGroup} form-group`}>
							<button className={styles.btnCustom} onClick={update}>
								<span>Join Now</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export const Modals = ({modalHide, state}) => {
	const {
		modal,
		showModel,
		idb: {showRegisterPop},
	} = useApp();
	const setModal = key => {
		if (key === modal) {
			modalHide('');
		} else modalHide(key);
		showModel('');
	};

	const timeOut = useRef(null);

	const getDeviceType = () => {
		const ua = window.navigator.userAgent;
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
			return 'tablet';
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				ua,
			)
		) {
			return 'mobile';
		}
		return 'desktop';
	};

	useEffect(() => {
		if (timeOut.current) clearTimeout(timeOut.current);
		const device = getDeviceType();
		const getPopUpShow = localStorage.getItem('show-pop');
		if (showRegisterPop && !getPopUpShow) {
			timeOut.current = setTimeout(() => {
				const modalKey = Object.values(state).every(v => v === false);

				if (modalKey) {
					localStorage.setItem('show-pop', true);
					if (device !== 'desktop') {
						document.body.style.overflow = 'hidden';
					}
					modalHide(device === 'desktop' ? 'register' : 'mobRegister');
				}
			}, 15000);
		}
	}, [modalHide, showRegisterPop, state]);

	const outSideModalClickEvent = () => {
		if (wrapperRef && !wrapperRef.current?.contains(event.target)) {
			removeListener();
			modalHide('');
			showModel('');
			sessionStorage.removeItem('varianceId');
			sessionStorage.removeItem('auctionId');
			sessionStorage.removeItem('attributes');
		}
	};

	const removeListener = () => {
		document.removeEventListener('mousedown', outSideModalClickEvent);
	};

	return (
		<>
			{/* login modal start */}
			{(state.loginModalShow || modal === 'login') && (
				<LoginPop
					showModel={setModal}
					wrapperRef={wrapperRef}
					outSideModalClickEvent={outSideModalClickEvent}
				></LoginPop>
			)}
			{/* login modal end */}
			{/* register modal start */}
			{(state.registerModalShow || modal === 'register') && (
				<RegisterPop
					showModel={setModal}
					wrapperRef={wrapperRef}
					outSideModalClickEvent={outSideModalClickEvent}
				></RegisterPop>
			)}
			{(state.registerMobModalShow || modal === 'mobRegister') && (
				<ShowMobRegister modalHide={modalHide} styles={styles} />
			)}
			{/* register modal end */}
			{/* forgot password start */}
			{state.forgotModalShow && (
				<ForgotPasswordPop
					showModel={setModal}
					wrapperRef={wrapperRef}
					outSideModalClickEvent={outSideModalClickEvent}
				></ForgotPasswordPop>
			)}
			{/* forgot password end */}
			{/* resend activation modal start  */}
			{state.resendActivationModalShow && (
				<ResendActicationPop
					showModel={setModal}
					wrapperRef={wrapperRef}
					outSideModalClickEvent={outSideModalClickEvent}
				></ResendActicationPop>
			)}
			{/* resend activation modal end */}
		</>
	);
};

export default withRouter(Header);
